import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import SurahCard from '../SurahCard/SurahCard';
import LoadingLottie from '../LoadingLottie/LoadingLottie';
import getAllSurahs from '../../lib/getAllSurahs';

export const Learn = () => {
	const { isLoading, data } = useQuery('allSurahs', getAllSurahs, {
		refetchOnMount: false,
	});

	if (isLoading) {
		return <LoadingLottie />;
	}
	let surahs = data.data.data.surahs;
	return (
		<div
			id='learn'
			className='container mx-auto flex flex-col justify-between items-center '
		>
			<h1 className='bg-p-dark rounded-lg py-3 px-6 text-p-lighter text-center text-5xl my-10 font-bold'>
				قائمة السور
			</h1>
			<div className='  flex flex-col-reverse md:flex-row-reverse md:flex-wrap-reverse items-center justify-center  p-5 pb-10'>
				{/* surahs displayed */}
				{surahs
					.filter((surah) => surah.number > 107)
					.map((surah) => (
						<Link
							key={surah.number}
							className='w-80 p-2'
							to={`/${surah.number}?currentAyah=1&reciter=ar.hudhaify`}
						>
							<SurahCard
								name={surah.name}
								number={surah.number}
								revelationType={surah.revelationType}
								ayahs={surah.ayahs.length}
							/>
						</Link>
					))}
				<Link
					className='w-80  p-2'
					to={`/${surahs[0].number}?currentAyah=1&reciter=ar.hudhaify`}
				>
					<SurahCard
						name={surahs[0].name}
						number={surahs[0].number}
						revelationType={surahs[0].revelationType}
						ayahs={surahs[0].ayahs.length}
					/>
				</Link>
			</div>
		</div>
	);
};
